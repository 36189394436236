<template>
  <b-card-code>
    <b-button
      type="button"
      variant="primary"
      class="my-1"
      size="sm"
      @click="gotoCreateNetwork()"
    >
      + Add New
    </b-button>
    <b-card-code class="bg-light-secondary mb-1" title="Search">
      <b-form inline style="display: inline-block; width: 100%">
        <div class="row" style="width: 100%; padding: 5px">
          <b-form-input
            class="ml-1"
            id="name"
            v-model="title_filter"
            style="width: 70%"
            name="name"
            placeholder="Search with Title"
          />
          <b-button
            style="width: 12%"
            class="ml-1"
            variant="secondary"
            @click="refresh"
          >
           Refresh
          </b-button>
          <b-button
            style="width: 12%"
            class="ml-1"
            variant="primary"
            @click="load"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>
              <feather-icon icon="SearchIcon" class="mr-50" />Search</span
            >
          </b-button>
        </div>
      </b-form>
    </b-card-code>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <b-table
      responsive
      id="atTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      v-if="!loading"
      hover
    >
      <template #cell(index)="data">
        {{ data.index + 1 + perPage * (currentPage - 1) }}
      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at | formatdate }}
      </template>
      <template #cell(actions)="data">
        <b-button
          variant="danger"
          size="sm"
          @click="gotoDelete(data.item.id, data.item.title)"
          >Delete</b-button
        >
      </template>
    </b-table>
    <div v-if="!items.length">
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
      >
        <feather-icon icon="GlobeIcon" size="34" class="mb-50" />
        <h3 class="font-weight-bolder">No Networks</h3>
        <p>
          To add a new Network,
          <span
            class="font-weight-bold text-success"
            @click="gotoCreateNetwork()"
            >Click here</span
          >
        </p>
      </div>
    </div>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="atTable"
    />
    <b-sidebar
      id="sidebar-create-network"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="600px"
    >
      <!-- Header -->
      <CreateNetwork :closeSidebar="closeSidebar" />
    </b-sidebar>

    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ networktitle }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteNetwork()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import moment from "moment";
import {
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BAvatar,
  BSidebar,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CreateNetwork from "./add.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    vSelect,
    BAvatar,
    BSidebar,
    CreateNetwork,
  },
  data() {
    return {
      pagination_pos: "center",
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        { key: "index", label: "#" },
        { key: "title", label: "Title" },
        { key: "created_at", label: "Created" },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      isloading: false,
      loading: false,
      openDeleteModal: false,
      networkId: null,
      network_title: "",
      title_filter:"",
    };
  },
  created: function () {
    this.load();
    // this.searchFn();
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    load: function () {
      let url = process.env.VUE_APP_BASEURL + "agent/network-location/";
      if(this.title_filter != ""){
        url = url + "?title=" + this.title_filter;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data;
        this.rows = res.data.length;
      });
    },
    gotoCreateNetwork() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-create-network");
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-create-network");
      this.load();
    },
    gotoDelete(id, title) {
      this.openDeleteModal = true;
      this.networkId = id;
      this.networktitle = title;
    },
    deleteNetwork() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "agent/network-location/" +
          this.networkId +
          "/",
      };
      this.$http(options).then((res) => {
        this.openDeleteModal = false;
        this.load();
      });
    },
    refresh(){
      this.title_filter= "";
      this.load();
    }
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
